import React, { useState, useEffect, useRef } from 'react';
import './index.scss';

export default function UserLi({ 
    userName, 
    colors, 
    isAfter, 
    isButton, 
    pseudoContent, 
    svgAfter, 
    isDisabled, 
    dataToken, 
    timer, 
    endOfMove, 
    isFroozenTimer 
}) {
    const [color, backgroundColor, pseudoColor, pseudoColorText] = colors && colors.length === 4 ? colors : ['#2E3B47', '#EAC155', '#55AA79', '#FFFFFF'];

    const [remainingTime, setRemainingTime] = useState(0);
    const isFrozenTimerRef = useRef(isFroozenTimer);

    useEffect(() => {
        isFrozenTimerRef.current = isFroozenTimer;
    }, [isFroozenTimer]);

    useEffect(() => {
        let interval;
        
        if (timer && endOfMove) {
            const endTime = new Date(endOfMove).getTime();
            const updateRemainingTime = () => {
                if (!isFrozenTimerRef.current)
                {
                    const currentTime = new Date().getTime();
                    setRemainingTime(Math.max(0, endTime - currentTime - 5000));
                }
            };
            updateRemainingTime(); 
            interval = setInterval(updateRemainingTime, 1000); 
        }

        return () => {
            if (interval) clearInterval(interval);
        };
    }, [timer, endOfMove]);

    return (
        <li
            className={`user-li${isAfter ? ' after' : ''}${timer ? ' timer' : ''}`}
            style={{
                color: color,
                backgroundColor: backgroundColor,
                '--pseudo-color': pseudoColor,
                '--pseudo-color-text': pseudoColorText,
                ...(timer ? { '--procent-of-move': `${100 - remainingTime / 1200}%` } : {}),
            }}
            data-pseudo-content={pseudoContent}
        >
            {isButton ? 
                <button disabled={isDisabled} style={{
                    '--pseudo-svg': `url(${svgAfter})`
                }}
                {...(dataToken ? { 'data-token': dataToken } : {})}
                >
                    {userName}
                </button> : 
                userName
            }
        </li>
    );
}
