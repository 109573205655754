import React, { useEffect, useState } from "react";
import './style.scss';
import ModalBlock from "../../common/modalBlock";
import { useNavigate } from "react-router-dom";

export default function Admin() {
    const [isAdmin, setIsAdmin] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        fetch('/server/http/checkAdminToken.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Ошибка проверки токена');
                }
                return response.json();
            })
            .then(data => {
                if (data.is_admin) {
                    setIsAdmin(true);
                } else {
                    navigate('/');
                }
            })
            .catch(() => navigate('/'));
    }, [navigate]);

    if (error) {
        return <div className="error">{error}</div>;
    }

    if (!isAdmin) {
        return <div>Загрузка...</div>;
    }

    return (
        <section className="admin">
            <ModalBlock h1={'Админ панель'} text={'Список кнопок для управления базой данных ниже'}>
                <button className="primary-button" onClick={deleteInactiveGames}>
                    Удалить все неактивные игры
                </button>
                <button className="primary-button" onClick={deleteAllUsers}>
                    Удалить всех пользователей
                </button>
                <button className="primary-button" onClick={deleteAllRoomsAndGames}>
                    Удалить все комнаты и игры
                </button>
                <button className="primary-button" onClick={addTestUsers}>
                    Добавить тестовых юзеров
                </button>
            </ModalBlock>
        </section>
    );

    function deleteInactiveGames() {
        fetch('/server/http/admin/deleteInactiveGames.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Ошибка удаления неактивных игр');
                }
                alert("Неактивные игры удалены");
            })
            .catch(err => alert("Ошибка: " + err.message));
    }

    function deleteAllUsers() {
        if (window.confirm("Вы уверены, что хотите удалить всех пользователей? Это действие необратимо!")) {
            fetch('/server/http/admin/deleteAllUsers.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Ошибка удаления пользователей');
                    }
                    alert("Все пользователи удалены");
                })
                .catch(err => alert("Ошибка: " + err.message));
        }
    }

    function deleteAllRoomsAndGames() {
        fetch('/server/http/admin/deleteAllRoomsAndGames.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Ошибка удаления комнат и игр');
                }
                alert("Все комнаты и игры удалены");
            })
            .catch(err => alert("Ошибка: " + err.message));
    }

    function addTestUsers() {
        fetch('/server/http/admin/addTestUsers.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Ошибка добавления тестовых пользователей');
                }
                alert("Тестовые пользователи добавлены");
            })
            .catch(err => alert("Ошибка: " + err.message));
    }
}
