import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import SVGScheme from './SVGScheme';
import ModalWindow from "../../common/modalWindow";
import UserLi from "../../common/userLi";
import './style.scss';

export default function Game () {
	const navigate = useNavigate();
    const location = useLocation();
    const urlParams = new URLSearchParams(window.location.search);
    const roomToken = urlParams.get('room_token');
	const token = document.cookie.split('; ').find(row => row.startsWith('player_token='))?.split('=')[1];
    const login = decodeURIComponent(document.cookie.split('; ').find(row => row.startsWith('player_login='))?.split('=')[1]);

	const [players, setPlayers] = useState([]);
	const [playerMove, setPlayerMove] = useState ({});
	const [chips, setChips] = useState([]);
    const [cells, setCells] = useState([]);
	const [chipsOnField, setChipsOnField] = useState([]);
	const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalText, setModalText] = useState('');
    const [modalType, setModalType] = useState('simpleText');
    const [isMyMove, setIsMyMove] = useState(false);
    const [wordForApproval, setWordForApproval] = useState('');
    const [isFroozenTimer, setIsFrozenTimer] = useState(false);

    const [userDragableChip, setUserDragableChip] = useState({});
    const [isBlocked, setIsBlocked] = useState(false);
    

    const [selectedLetter, setSelectedLetter] = useState({});
    const [swapedChips, setSwapedChips] = useState([]);


    const [playerChipsOnField, setPlayerChipsOnField] = useState([]);	

    
    const letterScores = {
        А: 1, И: 1, Р: 2, Ш: 10, Б: 3, Й: 2, С: 2, Щ: 10,
        В: 2, К: 2, Т: 2, Ъ: 10, Г: 3, Л: 2, У: 3, Ы: 5,
        Д: 2, М: 2, Ф: 10, Ь: 5, Е: 1, Н: 1, Х: 5, Э: 10,
        Ж: 5, О: 1, Ц: 10, Ю: 10, З: 5, П: 2, Ч: 5, Я: 3
    };

    
	const socketRef = useRef(null);


	useEffect(() => {
        if (!token) {
            navigate('/');
            return;
        }

        let reconnectAttempts = 0;
        const maxReconnectAttempts = 10;
        let isReconnecting = false;
    
        const connectSocket = () => {

            try{
                socketRef.current = new WebSocket(`wss://scrabble.gentofsite.ru:8080/game/?token=${token}&room_token=${roomToken}`);
            }
            catch{
                handleReconnectError('Ошибка при попытке установить соединение. Попробуйте обновить страницу.');
                return;
            }

            
            const socket = socketRef.current;

            if (socket)
            {
                socket.onopen = () => {
                    reconnectAttempts = 0; 
                };

                socket.onmessage = (event) => {
                    const data = JSON.parse(event.data);
                    if (data) {
                        if (data.winner)
                        {
                            setModalType('simpleText');
                            if (login === data.winner) setModalText("Вы выиграли! Поздравляем вас");
                            else setModalText(`Вы проиграли. Победитель: ${data.winner}`)
                            setIsModalOpen(true);

                            clearInterval(intervalId);
                            setIsMyMove(false);
                        }
                        if (data.status && data.status === 'error'){
                            setModalText(data.message);
                            setIsModalOpen(true);
                            if (data.can_approval == "true")
                            { 
                                setModalType('submitWord');                                
                                setWordForApproval(data.word);
                            }
                            else setModalType('simpleText');

                        }
                        if (data.approvals)
                        {
                            setWordForApproval(data.approvals);
                            setModalText(`${data.current_move.login} спрашивает, существует ли слово ${data.approvals}`);
                            setModalType('questionWord');    
                            setIsModalOpen(true);
                            setIsFrozenTimer(true);
                        }
                        if (data.is_blocked)
                        {
                            setIsBlocked(data.is_blocked);
                            if (data.is_blocked) setIsFrozenTimer(true);  
                        }
                        if (!data.is_blocked && !data.approvals)
                        {
                            setIsFrozenTimer(false);
                            setIsBlocked(false);
                        }
                        if (data.cells){
                            setCells(data.cells);
                        }
                        if (data.player_chips){
                            setChips(data.player_chips);
                        }
                        if (data.current_move){
                            setPlayerMove(data.current_move);
                            if (data.current_move.login == login) setIsMyMove(true);
                            else
                            {
                                setIsMyMove(false);
                                setPlayerChipsOnField([]);
                            } 
                        }
                        if (data.players){
                            setPlayers(data.players);
                        }
                        if (data.total_points){
                            setModalType('simpleText');
                            setModalText(`Этот ход принесет вам ${data.total_points} очков`);
                            setIsModalOpen(true);
                        }
                        if (data.chips_on_field){
                            setChipsOnField(data.chips_on_field);
                        }
                    }
                };

                socket.onerror = (error) => {
                    handleReconnectError('Ошибка при попытке установить соединение. Попробуйте обновить страницу.');
                };

                socket.onclose = () => {
                    handleReconnectError('Не удалось восстановить соединение. Попробуйте перезагрузить страницу.');
                };
            }

        }


        const handleReconnectError = (message) => {
            if (!isReconnecting && (reconnectAttempts < maxReconnectAttempts)) {
                isReconnecting = true;
                reconnectAttempts++;
                setTimeout(() => {
                    connectSocket();
                    isReconnecting = false;
                }, 5000); 
            } else if (reconnectAttempts >= maxReconnectAttempts) {
                isReconnecting = false;
                setModalType('simpleText');
                setModalText(message);
                setIsModalOpen(true);
            }
        };
    
        connectSocket();

        const intervalId = setInterval(() => {
            getGameInfo();
        }, 10000);

        return () => {
            if (socketRef.current) {
                socketRef.current.close();
            }
            clearInterval(intervalId); 
        };
    }, [token, roomToken, navigate]);


    useEffect(() => {
        if (playerMove && playerMove.end_of_move) {
            const endTime = new Date(playerMove.end_of_move).getTime();
            const currentTime = Date.now();
            const remainingTime = endTime - currentTime - 5000;
    
            if (isFroozenTimer) {
                const timeoutId = setTimeout(() => {
                    getGameInfo();
                }, 30000);
    
                return () => clearTimeout(timeoutId);
            }
    
            if (remainingTime > 0) {
                const timeoutId = setTimeout(() => {
                    if (isMyMove) {
                        skipMove();
                    } else {
                        const waitTimeoutId = setTimeout(() => {
                            getGameInfo();
                        }, 5000);
    
                        return () => clearTimeout(waitTimeoutId);
                    }
                }, remainingTime);
    
                return () => clearTimeout(timeoutId);
            }
        }
    }, [playerMove, isFroozenTimer]);
    
    
    const handleDragStart = (e, element) => {
        e.dataTransfer.setData('chipIndex', JSON.stringify(element)); 
    };


    const handleDragOver = (e) => {
        e.preventDefault(); 
    };
    
      
    const handleDrop = (e) => {
        e.preventDefault();
        const target = e.target;
        const chip = JSON.parse(e.dataTransfer.getData('chipIndex')); 

        if (target && target.tagName === 'path') {
            if (chip.value === "*")
            {
                setSelectedLetter ({
                    x: target.dataset.id.substring(5) % 15 === 0 ? 15 : target.dataset.id.substring(5) % 15 ,
                    y: Math.ceil(target.dataset.id.substring(5)/ 15) ,
                    id: chip.id
                })
                setIsModalOpen(true);
                setModalType('letterSelection');

            }
            else
                setPlayerChipsOnField((prevChips) => [
                    ...prevChips, 
                    {
                        x: target.dataset.id.substring(5) % 15 === 0 ? 15 : target.dataset.id.substring(5) % 15 ,
                        y: Math.ceil(target.dataset.id.substring(5)/ 15) ,
                        id: chip.id,
                        value: chip.value
                    }
                ]);
        }
    };

    const sendMessage = (message) => {
        if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
            socketRef.current.send(JSON.stringify(message));
        }
    };

    const sendChips = () => {
        const checkWordsData = {
            type: 'checkWords',
            payload: {
                chips: playerChipsOnField
            },
        };
        sendMessage(checkWordsData);
    }

    const endMove = () => {
        const sendEndMove = {
            type: 'endMove',
            payload: {
                chips: playerChipsOnField
            },
        };
        sendMessage(sendEndMove);
    }

    const skipMove = () => {
        const sendSkipMove = {
            type: 'skipMove',
            payload: {
                chips: playerChipsOnField
            },
        };
        sendMessage(sendSkipMove);
        setPlayerChipsOnField([]);
    }

    const sendExitGame = () => {
        const sendExitGame = {
            type: 'exitGame'
        };
        sendMessage(sendExitGame);
        navigate('/main-menu');
    }

    const swapChips = () => {
        setModalType("swapSelection");
        setIsModalOpen(true);
    }

    const getGameInfo = () => {
        const sendGetGameInfo = {
            type: 'getGameInfo',
        };
        sendMessage(sendGetGameInfo);
    }

    const swapChipsRequest = (event) => {
        event.preventDefault();

        const sendSwapChips = {
            type: 'swapChips',
            payload: {
                chips: swapedChips
            },
        };
        sendMessage(sendSwapChips);
        setPlayerChipsOnField([]);
        setSwapedChips([]);
        setIsModalOpen(false);
    }

    const sendWordForApproval = (word) => {
        const sendWordForApproval = {
            type: 'sendWordForApproval',
            payload: {
                wordForApproval: word
            },
        };
        sendMessage(sendWordForApproval);
        setIsModalOpen(false);
    }

    const sendIsApproval = (status) => {
        const sendApproval = {
            type: 'sendApproval',
            payload: {
                status: (status ? 'yes' : 'no')
            },
        };
        sendMessage(sendApproval);
        setIsModalOpen(false);
    }


    const changeLetter = (event) => {
        event.preventDefault();
        setPlayerChipsOnField((prevChips) => [
            ...prevChips, 
            selectedLetter
        ]);
        setSelectedLetter({});
        setIsModalOpen(false);
    };


	return (
        <>
		<section className="game" >
			<aside>
				{playerMove && playerMove.login && <h1>Ход игрока {playerMove && playerMove.login}</h1>}
				<ul className="player-list">
                    {players && players.length > 0 && players.map((el, k) => (
                        <UserLi 
                            userName={el.player}
                            key={k}
                            isAfter={k%2 === 1} 
                            pseudoContent={el.points}
                            colors={playerMove && playerMove.login === el.player ? [
                                '#FFFFFF',  
                                '#3C87E3',   
                                '#3C87E3',   
                                '#FFFFFF'    
                            ]:[
                                '#2E3B47',  
                                '#D2D3D7',   
                                '#A3AEAd',   
                                '#FFFFFF'    
                            ]
                            }
                            timer = {playerMove && playerMove.login === el.player && true}    
                            endOfMove = {playerMove.end_of_move}  
                            isFroozenTimer = {isFroozenTimer}                 
                        />
                    ))}
				</ul>
				<h2>Ваши фишки</h2>
				<ul className="chips-list">
                    {chips && chips.length !== 0 && chips.map((element, index) => (
                        <li 
                            key={index}
                            draggable={isMyMove}                             
                            onDragStart={(e) => handleDragStart(e, element)}
                            data-id={element.id}
                            
                            style={{
                                display: playerChipsOnField.some((chip) => chip.id === element.id) ? 'none' : 'flex'
                            }}
                        >
                            {element.value}
                            <span>{letterScores[element.value]}</span>                        
                        </li>
                    ))}
				</ul>
				<ul className="buttons-list">
                    <button className="primary-button" onClick={skipMove} disabled={(!isMyMove)}>пропустить ход</button>
                    <button className="primary-button" onClick={sendChips} disabled={(!isMyMove)||isBlocked}>проверить</button>
                    <button className="primary-button" onClick={swapChips} disabled={(!isMyMove)}>поменять фишки</button>
                    <button className="primary-button" onClick={endMove} disabled={(!isMyMove)||isBlocked}>закончить ход</button>
				</ul>

                <ul className="rules">
                    <li>простая клетка</li>
                    <li>умножение буквы&nbsp;на&nbsp;2</li>
                    <li>умножение слова&nbsp;на&nbsp;2</li>
                    <li>умножение буквы&nbsp;на&nbsp;3</li>
                    <li>умножение слова&nbsp;на&nbsp;3</li>
                </ul>

                <button className="back-button" onClick={sendExitGame}>выйти из игры</button>

			</aside>
            <div onDragOver={handleDragOver} onDrop={handleDrop}><SVGScheme userDragableChip={userDragableChip} cells={cells} playerChipsOnField = {playerChipsOnField} setPlayerChipsOnField={setPlayerChipsOnField} chipsOnField={chipsOnField}></SVGScheme></div>
		</section>

        {isModalOpen && (modalType === 'simpleText' ? <ModalWindow text={modalText}  onClose={() => {setIsModalOpen(false)}} /> :
          modalType === 'letterSelection' ?
         <ModalWindow 
            text={'Выберите букву, на которую вы хотите заменить *'}             
            type='radio'
            onClose={() => {setIsModalOpen(false); setSelectedLetter({})}}
            maxWidth="556px"
        > 
            <form className="select-letter" onSubmit={changeLetter}>
                {Object.entries(letterScores).map(([letter, score]) => (
                <label key={letter}>
                    <input type="radio" name="select-letter" value={letter} 
                    onClick={() => setSelectedLetter(prevState => ({...prevState, value: letter}))}/>
                    {letter}
                    <span>{score}</span>
                </label>
                ))}
                <button type="submit" className="primary-button" >Заменить</button>
            </form>

         </ModalWindow>
         :
          modalType === 'swapSelection' ?
          <ModalWindow 
            text={'Выберите буквы для замены'}             
            type='checkboxes'
            onClose={() => {setIsModalOpen(false); setSwapedChips({})}}
            maxWidth="556px"
           > 
            <form className="select-letter" onSubmit={swapChipsRequest}>
                {chips && chips.length > 0 && chips.map((letter, key) => (
                <label key={key}>
                    <input type="checkbox" name="select-letter" value={letter.value} 
                    onClick={() => setSwapedChips(prevState => ([...prevState, letter]))}/>
                    {letter.value}
                    <span>{letterScores[letter.value]}</span>
                </label>
                ))}
                <button type="submit" className="primary-button" >Заменить</button>
            </form>

         </ModalWindow> : 
         modalType === 'questionWord' ?
         <ModalWindow 
           text={modalText}             
           onClose={() => {setIsModalOpen(false); setWordForApproval('')}}
           maxWidth="350px"
          > 
            <div style={{display: "flex", gap: "8px"}}>
                <button type="button" className="primary-button" onClick={() => sendIsApproval(true)}>Да</button>
                <button type="button" className="primary-button" style={{background: "#ca564c"}} onClick={() => sendIsApproval(false)}>Нет</button>
            </div>
        </ModalWindow> :

        
        modalType === 'submitWord' &&
        <ModalWindow 
            text={modalText}             
            onClose={() => {setIsModalOpen(false); setWordForApproval('')}}
            maxWidth="350px"
            > 
            <button type="button" className="primary-button" onClick={() => sendWordForApproval(wordForApproval)}>Да</button>
        </ModalWindow>

        )}
        </>
	)
}
