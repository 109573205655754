import React from 'react';
import { useEffect } from 'react';
import Form from './Form';
import './style.scss';
import { useNavigate } from 'react-router-dom'

export default function Authorization() {
	const navigate = useNavigate();  

    useEffect(() => {
        const checkUserToken = async () => {
			const response = await fetch('/server/http/checkUserToken.php', {
				method: 'GET',
				credentials: 'include',
			});
			const result = await response.json();

			if (result.status == 'success') {
				navigate('/main-menu', {
					state: {
						onlineUsers: result.online_users,
						invites: result.invites,
					},
				});
			}
        };

        checkUserToken();
    }, [navigate]);

	return (
		<>
			<section className='authorization'>
				<div><img src="/logoBig.svg" alt="Логотип" /></div>
				<Form></Form>
			</section>
		</>
	);
}
